import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { ApiUrlProvide, headerProvider } from "../../../common/CommonFunctions";
import { useToast } from "@chakra-ui/react";
import NewActivityForm from "../LittleActsNewActivityForm/LittleActsNewActivity";
import { showErrorMessage } from "../../../common/CommonFunctions";
import { getLittleActsActivityList } from "../../../services/dataApi/dataProvider";

const LittleActsActivityList = () => {
  const Navigate = useNavigate();
  const toast = useToast();
  const pageNumLittleActs = localStorage.getItem("pageNumLittleActs");
  const pageNumber = pageNumLittleActs ?pageNumLittleActs:1;
  const sortingOrderLittleActs = localStorage.getItem("sortingOrderLittleActs");
  const [addActivityFormControl, setAddActivityFormControl] = useState(false);
  const [littleActsActivityList, setLittleActsActivityList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [onUpdate, setOnUpdate] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [svgContent, setSvgContent] = useState({});
  const [filter,setFilter] = useState('');

  const [sortingLittleActs, setSortingLittleActs] = useState({
    field: "createdOn",
    order: sortingOrderLittleActs ? sortingOrderLittleActs : "DESC",
  });
  const getAllLittleActsActivityList = useCallback(async () => {
    try {
      const queryParams = ApiUrlProvide(
        filter,
        pageNumber,
        itemsPerPage,
        sortingLittleActs.field,
        sortingLittleActs.order
      );
      const headers = headerProvider();
      const response = await getLittleActsActivityList(queryParams, headers);
      if (response) {
        setLittleActsActivityList(response.response);
        setPageCount(Math.ceil(response.totalCount / itemsPerPage));
      } else {
        showErrorMessage(
          "Server Error",
          "The server is not responding. Please try later!",
          "error",
          toast
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [filter,itemsPerPage,sortingLittleActs,toast,pageNumber]);
  useEffect(() => {
    try {
      getAllLittleActsActivityList();
    } catch (error) {
      console.log(error);
    }
  }, [getAllLittleActsActivityList]);

  const handleChange = async (event, value) => {
    if (value) {
      localStorage.setItem('pageNumLittleActs', value)
      const queryParams = ApiUrlProvide(filter, value, itemsPerPage, sortingLittleActs.field, sortingLittleActs.order)
      const headers = headerProvider()
      const response = await getLittleActsActivityList(queryParams, headers)
      setLittleActsActivityList(response?.response)
    }
  }

  const handleLittleActsDateFilter = async() => {
    const date = localStorage.getItem("dateFilter")
    if(date === ''){
      setFilter('');
    }
    else{
    const selectedDate = new Date(date);
    const filterDate = selectedDate.getFullYear() +'-'+ (selectedDate.getMonth() +1);
    setFilter(filterDate);
    }
  };
  const confirmDelete = () => {
    handleDeleteActivity(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const cancelDelete = () => {
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const onCloseAddActivity = () => {
    setAddActivityFormControl(!addActivityFormControl);
  };
  const onSubmitAddActivity = () => {

    getAllLittleActsActivityList();
  };

  const handleDeleteActivity = (data) => {

    const updatedList = littleActsActivityList.filter((item) => {
      if (item.id !== data.id) {
        return item;
      }
      return []
    });
    localStorage.setItem("littleActsData", JSON.stringify(updatedList));

    getAllLittleActsActivityList();
  };
  const renderMonthYear = (date) => {
    const monthandYear = new Date(date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', });
    return monthandYear;
  }  
  useEffect(() => {
    littleActsActivityList.forEach((item) => {
      if (item?.url.endsWith('.svg')) {
        fetch(item?.url)
          .then((response) => response.text())
          .then((data) => {
            setSvgContent((prevContent) => ({
              ...prevContent,
              [item?.id]: data,
            }));
          })
          .catch((error) => console.error('Error fetching SVG:', error));
      }
    });
  }, [littleActsActivityList]);
  const handleSingleActivityClick = (activity) => {
    Navigate(`/little-acts/${activity.groupId}`, { state: activity?.groupId });
  }
  return (
    <div className="h-screen flex">
      <div className="basis-1/5">
        <Sidebar />
      </div>
      <div className="flex basis-4/5 flex-col bg-[#f4f7fe]">
        <div>
          <Navbar
            addNewActivity={onCloseAddActivity}
            littleActsDateFilter={handleLittleActsDateFilter}
            setOnUpdate={setOnUpdate}
          />
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-6">
           <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="p-2 text-center">
                  Image
                </th>
                <th scope="col" className="p-2 text-center">
                  Description
                </th>
                <th scope="col" className="p-2 text-center">
                  Created on
                </th>

                <th scope="col" className="p-2 text-center">
                  Created For
                </th>
              </tr>
            </thead>
            <tbody>
              {littleActsActivityList.map((item, index) => {
                const isSvg = item?.url.endsWith('.svg');
                return (
                  <tr
                    key={item?.id}
                    onClick={() => { handleSingleActivityClick(item) }}
                    className="relative transition-all duration-200 odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 w-full"
                  >
                    <td className="text-center flex justify-center">

                      {isSvg && svgContent[item?.id] ?
                        <div
                          className="w-16 h-16 p-2"
                          dangerouslySetInnerHTML={{ __html: svgContent[item?.id] }}
                        /> : <img
                          className="w-16 h-16  p-2"
                          src={item?.url}
                          alt={item?.url}
                        />

                      }
                    </td>
                    <td className="text-center">
                      <p className="text-base font-semibold text-gray-900 dark:text-gray-100">
                        {item?.description.length > 15 ? item?.description.slice(0, 15) + '...' : item?.description}                    </p>
                    </td>
                    <td className="text-center">
                      <p className="text-base font-semibold text-gray-900 dark:text-gray-100">
                        {new Date(item?.createdOn).toLocaleDateString()}
                      </p>
                    </td>

                    <td className="text-center">
                      <p className="text-base font-semibold text-gray-900 dark:text-gray-100">
                        {renderMonthYear(item?.scheduledOn)}
                      </p>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {showDeleteConfirm && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
            <div className="bg-white rounded-lg p-8 shadow-lg text-center">
              <h2 className="text-lg font-bold mb-4">
                Are you sure you want to delete this item?
              </h2>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={confirmDelete}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                >
                  Yes, Delete
                </button>
                <button
                  onClick={cancelDelete}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="mb-10 flex justify-center h-20 items-center">
          <div>
            <Pagination
              defaultPage={parseInt(pageNumber)}
              count={pageCount}
              color="primary"
              onChange={handleChange}
            />
          </div>
        </div>
        {addActivityFormControl && !onUpdate && (
          <NewActivityForm
            onClose={onCloseAddActivity}
            onSubmit={onSubmitAddActivity}
            onUpdate={onUpdate}
          />
        )}
        {addActivityFormControl && onUpdate && (
          <NewActivityForm
            onClose={onCloseAddActivity}
            onSubmit={onSubmitAddActivity}
            onUpdate={onUpdate}
          />
        )}
      </div>
    </div>
  );
};

export default LittleActsActivityList;
