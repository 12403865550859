import React, { useCallback, useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'
import { MdCheckCircle } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { getLettersList } from '../../../services/dataApi/dataProvider';
import { ApiUrlProvide, headerProvider, showErrorMessage } from '../../../common/CommonFunctions';
import { useToast } from '@chakra-ui/react'
import Pagination from '@mui/material/Pagination';




const LettersList = () => {
    const Navigate = useNavigate()
    const toast = useToast()
    const pageNumLetter = localStorage.getItem('pageNumLetter')
    const sortingFieldLetters = localStorage.getItem('sortingFieldLetters')
    const sortingOrderLetters = localStorage.getItem('sortingOrderLetters')
    const [lettersList, setLettersList] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(pageNumLetter ? pageNumLetter : 1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [filter, setFilter] = useState({})
    const [sortingLetters, setSortingLetters] = useState({
        field: sortingFieldLetters ? sortingFieldLetters : 'createdOn',
        order: sortingOrderLetters ? sortingOrderLetters : 'DESC'
    })
    const currentDate = new Date().toLocaleDateString()

    const getAllLetters = useCallback(async () => {
        try {
            const queryParams = ApiUrlProvide(filter, pageNumber, itemsPerPage, sortingLetters.field, sortingLetters.order)
            const headers = headerProvider()
            const response = await getLettersList(queryParams, headers)
            if(response){
                setLettersList(response.response)
                setPageCount(Math.ceil(response.totalCount / itemsPerPage))
            }
            else {
                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            }
        } catch (error) {
            console.log(error);
        }

    }, [])

    useEffect(() => {
        try {
            getAllLetters()
        } catch (error) {
            console.log(error);
        }
    }, [getAllLetters])

    const handleChange = async (event, value) => {
        if (value) {
            localStorage.setItem('pageNumLetter', value)
            const queryParams = ApiUrlProvide(filter, value, itemsPerPage, sortingLetters.field, sortingLetters.order)
            const headers = headerProvider()
            const response = await getLettersList(queryParams, headers)
            setLettersList(response.response)
        }
    }

    const sortByMethod = async (e) => {
        if (e.field) {
            localStorage.setItem('sortingFieldLetters', e.field)
            localStorage.setItem('sortingOrderLetters', e.order)
            setSortingLetters({ field: e.field, order: e.order })
            const queryParams = ApiUrlProvide(filter, pageNumber, itemsPerPage, e.field, e.order)
            const headers = headerProvider()
            const response = await getLettersList(queryParams, headers)
            if (response) {
                setLettersList(response.response)
            }
            else {
                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            }
        }
    }

    const handleClick = (item) => {
        if (item) {
            Navigate(`/letters/${item.id}`, { state: item.id })
        }
    }
    return (
        <div className='h-screen flex'>
            <div className='basis-1/5'>
                <Sidebar />
            </div>
            <div className='flex basis-4/5 flex-col bg-[#f4f7fe]'>
                <div>
                    <Navbar sortByMethod={sortByMethod} />
                </div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Heading
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    isActive
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created On
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lettersList.map((item) => {
                                    return <tr key={item.id} onClick={() => handleClick(item)} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 cursor-pointer hover:bg-gray-100 w-full">
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{item.heading}</p>
                                        </td>
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{item.type}</p>
                                        </td>
                                        <td className="px-6 py-2">
                                            {item.isActive ? <MdCheckCircle className='text-green-500' size={24} /> : <MdCancel className='text-red-500' size={24} />}
                                        </td>
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{(new Date(item.createdOn)).toLocaleDateString()}</p>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className='mb-10 flex justify-center h-20 items-center'>
                    <div>
                        <Pagination defaultPage={parseInt(pageNumber)} count={pageCount} color="primary" onChange={handleChange} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LettersList