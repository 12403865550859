import React, { useEffect, useState, useCallback } from 'react'
import Sidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'
import { Formik, Form, Field, replace } from 'formik';
import { IoClose } from "react-icons/io5";
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { Select } from '@chakra-ui/react'
import { createCarUser, getCarConversationList } from '../../../services/dataApi/dataProvider';
import { ApiUrlProvide, headerProvider, showErrorMessage } from '../../../common/CommonFunctions';
import { useToast } from '@chakra-ui/react'
import Pagination from '@mui/material/Pagination';






const StudyUsersList = () => {
    const [showModal, setShowModal] = useState(false)
    const [userConvoModal, setUserConvoModal] = useState(false)
    const Navigate = useNavigate()
    const pageNumCarConvo = localStorage.getItem('pageNumCarConvo')
    const sortingFieldCarConvo = localStorage.getItem('sortingFieldCarConvo')
    const sortingOrderCarConvo = localStorage.getItem('sortingOrderCarConvo')
    const toast = useToast()
    const [carConversationList, setCarConversationList] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(pageNumCarConvo ? pageNumCarConvo : 1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [filter, setFilter] = useState('all')
    const [sortingConvo, setSortingConvo] = useState({
        field: sortingFieldCarConvo ? sortingFieldCarConvo : 'localDateTime',
        order: sortingOrderCarConvo ? sortingOrderCarConvo : 'DESC'
    })
    const [studyUserDetails, setStudyUserDetails] = useState({
        emailId: '',
        userType: ''
    })
    const [firstMessageToUser, setFirstMessageToUser] = useState({
        textMessage: ''
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (userConvoModal) {
            setFirstMessageToUser((inputData) => ({ ...inputData, [name]: value }))
        }
        else {
            setStudyUserDetails((inputData) => ({ ...inputData, [name]: value }))
        }
    }
    const showModalFun = () => {
        setUserConvoModal(false)
        setShowModal(!showModal)
    }
    const handleClick = (item) => {
        if (item.userType !== 'control') {
            if (!item.convoStarted) {
                setUserConvoModal(!userConvoModal)
                setShowModal(!showModal)
            }
            else {
                if (item) {
                    Navigate(`/studyUsers/${item.id}`, { state: item.id })
                }
            }
        }
    }

    const getCarUserConversations = useCallback(async () => {
        try {
            const queryParams = ApiUrlProvide(filter, pageNumber, itemsPerPage, sortingConvo.field, sortingConvo.order)
            const headers = headerProvider()
            const response = await getCarConversationList(queryParams, headers, filter)
            if (response) {
                if (response.response.length > 0) {
                    setCarConversationList(response.response)
                    setPageCount(Math.ceil(response.totalCount / itemsPerPage))
                }
            }
            else {
                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            }
        } catch (error) {
            console.log(error);
        }

    }, [filter, pageNumber, itemsPerPage, sortingConvo.field, sortingConvo.order, toast])

    useEffect(() => {
        try {
            getCarUserConversations()
        } catch (error) {
            console.log(error);
        }
    }, [filter, pageNumber, itemsPerPage, sortingConvo.field, sortingConvo.order, toast, showModal])

    const sortByMethod = async (e) => {
        if (e.field) {
            setFilter(e.field)
            const queryParams = ApiUrlProvide(filter, pageNumber, itemsPerPage, e.field, e.order)
            const headers = headerProvider()
            const response = await getCarConversationList(queryParams, headers, filter)
            if (response) {
                setCarConversationList(response.response)
            }
            else {
                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            }
        }

    }

    const users = [
        {
            username: "Virat Kohli",
            userid: 201,
            userType: "control",
            enrollmentDate: new Date(),
            messageType: 'text',
            messageCount: 20,
            updatedDate: new Date(),
            convoStarted: true,
            id: 1
        },
        {
            username: "Rohit Sharma",
            userid: 202,
            userType: "trail",
            enrollmentDate: new Date(),
            messageType: 'text',
            messageCount: 20,
            updatedDate: new Date(),
            convoStarted: true,
            id: 2
        },
        {
            username: "Jasprit bumrah",
            userid: 203,
            userType: "control",
            enrollmentDate: new Date(),
            messageType: 'text',
            messageCount: 20,
            updatedDate: new Date(),
            convoStarted: true,
            id: 3
        },
        {
            username: "Hardik Pandya",
            userid: 204,
            userType: "trail",
            enrollmentDate: new Date(),
            messageType: 'text',
            messageCount: 20,
            updatedDate: new Date(),
            convoStarted: false,
            id: 4
        },
    ];
    return (
        <div className='h-screen flex'>
            <div className='basis-1/5'>
                <Sidebar />
            </div>
            <div className='flex basis-4/5 flex-col bg-[#f4f7fe]'>
                <Modal
                    isOpen={showModal}
                    ariaHideApp={false}
                    className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
                    contentLabel="Reply Modal"
                >
                    <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 w-full max-w-lg h-auto">
                        <div className="cursor-pointer absolute top-2 right-2 text-gray-700 dark:text-gray-300 hover:text-red-500">
                            <IoClose size={30} onClick={showModalFun} />
                        </div>
                        {
                            userConvoModal ?
                                <Formik
                                    initialValues={{
                                        replyMessage: "",
                                    }}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        console.log(firstMessageToUser);
                                        setFirstMessageToUser({
                                            textMessage: ''
                                        })
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form className="p-4">
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-700 dark:text-gray-300 mb-2"
                                                    htmlFor="replyMessage"
                                                >
                                                    Send your first message
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    className="block w-full text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md p-2"
                                                    type="text"
                                                    name="textMessage"
                                                    value={firstMessageToUser.textMessage}
                                                    placeholder="Type your message"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="flex justify-end">
                                                <button
                                                    type="submit"
                                                    disabled={!firstMessageToUser.textMessage}
                                                    className={`w-full h-10 rounded-md ${firstMessageToUser.textMessage
                                                        ? "bg-blue-500 text-white"
                                                        : "bg-blue-300 text-gray-500"
                                                        }`}
                                                >
                                                    Send
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                                :
                                <Formik
                                    initialValues={{
                                        replyMessage: "",
                                    }}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        console.log(studyUserDetails);
                                        const headers = headerProvider();
                                        const requestOptions = {
                                            method: "PUT",
                                            headers,
                                            body: JSON.stringify(studyUserDetails),
                                        };
                                        const response = await createCarUser(requestOptions);
                                        if (response) {
                                            if (response.success) {
                                                showErrorMessage(
                                                    'User Added',
                                                    'User has been added to the CAR study list.',
                                                    "success",
                                                    toast
                                                );
                                                // setShowModal(false);
                                            } else {
                                                showErrorMessage(
                                                    "Invalid user",
                                                    response.response.errors[0],
                                                    "error",
                                                    toast
                                                );
                                            }
                                        } else {
                                            showErrorMessage(
                                                "Server Error",
                                                "The server is not responding. Please try later!",
                                                "error",
                                                toast
                                            );
                                        }
                                        setStudyUserDetails({
                                            emailId: '',
                                            userType: studyUserDetails.userType
                                        })
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form className="p-4">
                                            <div>
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-700 dark:text-gray-300 mb-2"
                                                        htmlFor="replyMessage"
                                                    >
                                                        Add user
                                                    </label>
                                                    <Field
                                                        as="textarea"
                                                        className="block w-full text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md p-2"
                                                        type="text"
                                                        name="emailId"
                                                        value={studyUserDetails.emailId}
                                                        placeholder="Enter email"
                                                        onChange={handleChange}
                                                    />
                                                    <div className='mt-4'>
                                                        <Select name='userType' placeholder='Select User Type' onChange={handleChange} bg='white'>
                                                            <option value='trail'>Trail</option>
                                                            <option value='control'>Control</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="flex justify-end">
                                                    <button
                                                        type="submit"
                                                        className={`w-full h-10 rounded-md bg-blue-500 text-white`}>
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                        }
                    </div>
                </Modal>
                <div>
                    <Navbar showModalFun={showModalFun} sortByMethod={sortByMethod} />
                </div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Username
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    User Id
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    User Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Enrollment date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Message Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Message count
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated date
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                carConversationList.map((item) => {
                                    return <tr key={item.userID} onClick={() => handleClick(item)} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 cursor-pointer hover:bg-gray-100 w-full">
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{item.username}</p>
                                        </td>
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{item.userID}</p>
                                        </td>
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{item.userType}</p>
                                        </td>
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{new Date(item.enrollmentDate).toLocaleDateString()}</p>
                                        </td>
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{item.messageType ? item.messageType : 'Not available'}</p>
                                        </td>
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{item.messageCount ? item.messageCount : 'Not available'}</p>
                                        </td>
                                        <td className="px-6 py-2">
                                            <p className='text-base font-semibold'>{item.latestMessagetiming ? new Date(item.latestMessagetiming).toLocaleDateString() : 'Not available'}</p>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className='mb-10 flex justify-center h-20 items-center'>
                    <div>
                        <Pagination defaultPage={parseInt(pageNumber)} count={pageCount} color="primary" onChange={handleChange} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudyUsersList