import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner, Select, useToast } from '@chakra-ui/react';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import Sidebar from '../../../components/Sidebar';
import { headerProvider, showErrorMessage } from '../../../common/CommonFunctions';
import { addLang, deleteActivity,getSingleLittleActsActivity } from '../../../services/dataApi/dataProvider';
import Modal from 'react-modal';
import NewActivityForm from '../LittleActsNewActivityForm/LittleActsNewActivity';
import Cookies from 'js-cookie';

const SingleActivity = () => {
    const location = useLocation();
    const Navigate = useNavigate();
    const toast = useToast();
    const language = localStorage.getItem('language')
    const [showDescForm, setShowDescForm] = useState(false);
    const [newDescription, setNewDescription] = useState("");
    const [lang, setLang] = useState(language?language:'en-in')
    const [allLangActivity, setAllLangActivity] = useState([]);
    const [loader, setLoader] = useState(false)
    const [activity, setActivity] = useState();
    const [baseActivity, setBaseActivity] = useState();
    const [deleteAlertActivity, setDeleteAlertActivity] = useState(false)
    const [onEdit, setOnEdit] = useState(false);
    const [isSvgImage, setIsSvgImage] = useState(true);
    const getSingleActivityData = useCallback(async () => {
        try {
            const id = location.state;
            setLoader(true)
            const headers = headerProvider()
            const response = await getSingleLittleActsActivity(id, headers)
            setAllLangActivity(response?.response)
            const myActivity = response?.response.filter((item) => {
                return item?.language === lang
            })
            let base = response?.response.filter((item) => {
                return item?.language === 'en-in'
            })
            if (base[0]?.url.endsWith('.svg')) {
                setIsSvgImage(true);
                await fetch(base[0]?.url)
                    .then((response) => response.text())
                    .then((data) => {
                        base[0].url = data.replace('<svg', '<svg class="w-full h-full"');
                    })
                    .catch((error) => console.error('Error fetching SVG:', error));
            }
            else {
                setIsSvgImage(false);
            }
            setActivity(...myActivity)
            setBaseActivity(...base)
            setLoader(false)
        } catch (error) {
            console.log(error);
        }
    }, [location.state, lang])

    useEffect(() => {
        getSingleActivityData()
    }, [getSingleActivityData])

   const handleAddDescChange =(descr)=>{
    setActivity(prevActivity => ({
        ...prevActivity,
        description: descr
      }));
      setNewDescription(descr);
   }

    const handleAddLangChange = (e) => {
        localStorage.setItem('language',e.target.value)
        setLang(e.target.value)
    }
    const handleAddLangClick = () => {
        setShowDescForm(true);
    }

    const addLanguage = async(data) => {
        const token = Cookies.get('token')
    const headers = new Headers();
    headers.append("X-ZUMO-AUTH", token);
    headers.append("Content-Type", 'application/json');
    const requestOptions = {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
      redirect: "follow"
    };

    const response = await addLang(requestOptions);
    if (response && response.success) {
      showErrorMessage(
        "Updated",
        "Activity description has been updated successfully.",
        "success",
        toast
      );
    } else {
      showErrorMessage(
        response ? "Failed" : "Server Error",
        response ? response?.response?.errors[0] : "The server is not responding. Please try later!",
        "error",
        toast
      );
    }
    setNewDescription("");
    setShowDescForm(false);
    getSingleActivityData();
    };

    const handleDeleteAlert = () => {
        setDeleteAlertActivity(!deleteAlertActivity);
    }

    const handleOnEditClick = () => {
        setOnEdit(!onEdit);
    }

    const handleDeleteActivity = async () => {
        const headers = headerProvider();
        const requestOptions = {
            method: "DELETE",
            headers,
        };
        const response = await deleteActivity(activity?.groupId, requestOptions);
        if (response && response.success) {
            showErrorMessage(
                "Deleted",
                "Activity has been deleted successfully.",
                "success",
                toast
            );
            Navigate('/little-acts', { replace: true });
        } else {
            showErrorMessage(
                response ? "Failed" : "Server Error",
                response ? response?.response : "The server is not responding. Please try later!",
                "error",
                toast
            );
            handleDeleteAlert();
        }
        getSingleActivityData();
    }
    const handleEditActivity = () => {
        getSingleActivityData();
        handleOnEditClick();
        
    }
    
    return (
        <div className='flex'>
            <aside className="h-screen sticky top-0 basis-1/5">
                <Sidebar />
            </aside>
            <main className='basis-4/5 bg-[#f4f7fe]'>
                {loader ? (
                    <div className='flex justify-center self-center mt-64'>
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </div>
                ) : (
                    <div>
                        <div className='h-28 flex justify-start items-center p-5 border shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10'>
                            <p className='font-semibold text-xl'>Little Acts</p>
                        </div>
                        <div className="mt-5 flex justify-center">
                            <div className="flex flex-col min-h-92 bg-white w-10/12 shadow-xl p-4 rounded-lg mb-8 mt-4">
                                <div className="flex">

                                    <div className="w-1/3 flex flex-col justify-between item-center">
                                        {isSvgImage ?
                                            <div
                                                className="w-32 h-32 object-cover rounded-lg mb-4 shadow-md"
                                                dangerouslySetInnerHTML={{ __html: `${baseActivity?.url}` }}
                                            />
                                            : <img
                                                className="w-32 h-32 object-cover rounded-lg mb-4 shadow-md"
                                                src={activity?.url}
                                                alt={baseActivity?.description}
                                            />}
                                        <div className="flex justify-between items-center p-4 bg-white dark:bg-gray-800 rounded-md shadow-sm">
                                            <p className="text-md font-semibold text-gray-700 dark:text-gray-300">
                                                Created on
                                            </p>
                                            <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                                                {new Date(baseActivity?.createdOn).toLocaleDateString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "2-digit",
                                                })}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center p-4 bg-white dark:bg-gray-800 rounded-md shadow-sm">
                                            <p className="text-md font-semibold text-gray-700 dark:text-gray-300">
                                                Scheduled for
                                            </p>
                                            <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                                                {new Date(baseActivity?.scheduledOn).toLocaleDateString("en-GB", {
                                                    month: "long",
                                                    year: "numeric",
                                                })}
                                            </p>
                                        </div>
                                    </div>
                                    {/* Description Section */}
                                    <div className="ml-6 w-2/3 flex flex-col shadow-md bg-gray-50 dark:bg-gray-900 rounded-lg p-6">
                                        <h2 className="text-lg font-semibold mb-4 text-gray-800 dark:text-gray-100">
                                            Description
                                        </h2>
                                        <Select onChange={handleAddLangChange} value={lang}>
                                            <option value='en-in'>English</option>
                                            <option value='kn'>Kannada</option>
                                            <option value='hi'>Hindi</option>
                                        </Select>

                                        {/* Scrollable content */}
                                        <div className="mr-5 max-h-96 pr-2">
                                            {activity?.description ? <p className="p-5 w-full text-base text-gray-600 dark:text-gray-400 leading-relaxed">{activity.description}</p> :
                                                <button onClick={() => handleAddLangClick()}
                                                    className="mt-4 py-2.5 px-5 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white"
                                                >
                                                    <MdAdd size={20} />
                                                </button>}

                                            {/* Button to add more languages */}

                                        </div>

                                    </div>

                                </div>
                                <div className='flex flex-row justify-end '>
                                    <div className="flex justify-end mt-2">
                                        <div
                                            className="cursor-pointer shadow-md rounded-full p-3 "
                                            onClick={handleOnEditClick}

                                        >
                                            <MdEdit size={24} />
                                        </div>
                                    </div>
                                    <div className="flex justify-end mt-2 ml-5">
                                        <div
                                            className="cursor-pointer shadow-md rounded-full p-3 "
                                            onClick={handleDeleteAlert}

                                        >
                                            <MdDelete size={24} />
                                        </div>
                                    </div>
                                </div>
                                <Modal
                                    isOpen={deleteAlertActivity}
                                    ariaHideApp={false}
                                    className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
                                    contentLabel="Delete Modal"
                                >
                                    <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-md mx-auto">
                                        <div className="flex flex-col items-center">
                                            <p className="text-base font-medium text-gray-800 dark:text-gray-100 mb-6">
                                                Are you sure you want to delete this activity?
                                            </p>
                                            <div className="flex justify-center gap-4">
                                                <button
                                                    onClick={handleDeleteActivity}
                                                    type="button"
                                                    className="py-2 px-6 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    onClick={handleDeleteAlert}
                                                    type="button"
                                                    className="py-2 px-6 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-red-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-red-300 transition-colors"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                {onEdit && <NewActivityForm
                                    onClose={handleOnEditClick}
                                    onSubmit={handleEditActivity}
                                    onUpdate={true}
                                    activityObj={baseActivity}
                                    allLangActivity={allLangActivity}
                                />}
                                {showDescForm && (
                                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
                                        <div className="mt-4 p-4 bg-gray-100 w-1/3 h-1/7 dark:bg-gray-800 rounded-lg shadow-md">
                                            <p className="text-base font-medium text-gray-800 dark:text-gray-100 mb-6">Add Description for {activity?.language === 'kn' ? 'Kannada' : 'Hindi'}</p>

                                            <textarea
                                                value={newDescription}
                                                onChange={(e) => handleAddDescChange(e.target.value)}
                                                placeholder="Enter description"
                                                className="w-full p-2 mb-2 text-gray-900 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
                                            />

                                            <div className="flex justify-center gap-4">
                                                <button
                                                    onClick={()=>addLanguage(activity)}
                                                    type="button"
                                                    className="py-2 px-6 text-sm font-medium text-white bg-green-500 rounded-lg hover:bg-green-600 transition-colors"
                                                >
                                                    Add
                                                </button>
                                                <button
                                                    onClick={()=>setShowDescForm(false)}
                                                    type="button"
                                                    className="py-2 px-6 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-red-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-red-300 transition-colors"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
};

export default SingleActivity;
