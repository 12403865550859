import React, { useCallback, useEffect, useRef, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import { headerProvider } from '../../../common/CommonFunctions';
import { getSingleLetter } from '../../../services/dataApi/dataProvider';
import { Spinner } from '@chakra-ui/react'
import { useLocation, useNavigate } from "react-router-dom";
import { Select } from '@chakra-ui/react'
import { FaEdit } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import Tooltip from '@mui/material/Tooltip';




const SingleLetters = () => {
  const [letter, setLetter] = useState()
  const [allLetters, setAllLetters] = useState()
  const [lang, setLang] = useState('en-in')
  const [loader, setLoader] = useState(false)
  const location = useLocation();
  const Navigate = useNavigate()


  const getSingleLetterData = useCallback(async () => {
    try {
      const id = location.state;
      setLoader(true)
      const headers = headerProvider()
      const response = await getSingleLetter(id, headers)
      setAllLetters(response.response)
      const myLetter = response.response.filter((item) => {
        return item.language === lang
      })
      setLetter(...myLetter)
      setLoader(false)
    } catch (error) {
      console.log(error);
    }

  }, [location.state, lang])

  const handleChange = (e) => {
    const myLetter = allLetters.filter((item) => {
      return item.language === e.target.value
    })
    setLetter(...myLetter)
  }

  useEffect(() => {
    getSingleLetterData()
  }, [getSingleLetterData])

  return (
    <div className='flex'>
      <aside className="h-screen sticky top-0 basis-1/5">
        <Sidebar />
      </aside>
      <main className='basis-4/5 bg-[#f4f7fe]'>
        {
          loader ?
            <div className='flex justify-center self-center mt-64'>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </div>
            :
            <div>
              <div className='h-28 flex justify-end items-center p-5 border shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10'>
                <div>
                  <div className='flex items-center p-3 py-2.5 px-5 me-2 mb-2 mr-12'>
                    <Select placeholder='' onChange={handleChange} bg='white'>
                      <option value='en-in'>English</option>
                      <option value='kn'>Kannada</option>
                      {/* <option value='hi'>Hindi</option> */}
                    </Select>
                  </div>
                </div>
              </div>
              <div className='mt-5 flex justify-center'>
                {
                  letter?.heading ?
                    <div className='flex flex-col min-h-96 bg-white w-10/12 shadow-xl p-5 rounded-md mb-10 mt-4'>
                      <div className='flex justify-between mb-5 border-b'>
                        <Tooltip title="Heading" placement='top'>
                          <p className='mb-2 font-medium cursor-pointer'>{letter?.heading}</p>
                        </Tooltip>
                        <Tooltip title="Type" placement='top'>
                          <p className='mb-2 font-medium cursor-pointer'>{letter?.type.toLocaleUpperCase()}</p>
                        </Tooltip>
                      </div>
                      <div>
                        <p className='text-base p-4 -mt-2'>
                          {letter?.text}
                        </p>
                        <div className='flex justify-between border-t'>
                          <Tooltip title="Created on" placement='top'>
                            <div className='flex justify-center cursor-pointer'>
                              <p className='text-md p-4 -mt-2 font-medium'>
                                {(new Date(letter?.createdOn).toLocaleDateString())}
                              </p>
                            </div>
                          </Tooltip>
                          <Tooltip title="Edit" placement='top'>
                            <div className='cursor-pointer bg-white flex justify-end p-2' onClick={() => Navigate(`/createLetter`, {
                              state: {
                                lang: letter.language,
                                letter
                              }
                            })}>
                              <MdEdit size={30} />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    :
                    <div>
                      <p className='font-bold text-xl text-center mt-40'>No letter is available for this langague</p>
                      <div className='flex w-full mt-5 mb-5 justify-center items-center'>
                        <button onClick={() => Navigate(`/createLetter`, {
                          state: {
                            lang: letter.language,
                            letter
                          }
                        })} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                          Add Letter
                        </button>
                      </div>
                    </div>
                }
              </div>
            </div>
        }
      </main>
    </div>
  )
}

export default SingleLetters